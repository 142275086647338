<template>
  <v-app id="inspire"  style="background: #000000">
    <v-main>
      <span v-if="distanceToLove > 0" style="font-family: Ubuntu; color: #FFFFFF; font-size: 80%; margin: 1vw;">I am {{ distanceToLove }}km away from home <v-icon x-small color="#f44336">mdi-heart</v-icon></span>
      <v-container v-if="mobile" style="margin-top: 10vh; left: 10vw; right: 10vw; width: 80vw; height: 80vh;">
        <div style="position: absolute; left: 10%; top: 25%;">
          <div>
            <span style="font-family: Ubuntu; font-weight: bold; color: #FFFFFF; font-size: 340%;">Antony.</span>
          </div>
          <div id="Description">
            <p style="width: 90%;"><span style="font-family: Open Sans; font-weight: bold; color: #dc143c; font-size: 120%;">Student</span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">,</span><span style="font-family: Open Sans; font-weight: bold; color: #ffcf40; font-size: 120%;"> Software Developer</span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">, and</span><span style="font-family: Open Sans; font-weight: bold; color: #668cff; font-size: 120%;"> UI/UX Designer </span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">from the Netherlands.</span></p>
          </div>
          <br>
          <div id="Buttons" style="width: 95%;" class="text-left">
            <v-btn v-if="githubSwitch" class="mx-0" href="https://github.com/AntonyElfferich" target="_blank" fab dark small text>
              <v-icon dark>mdi-github</v-icon>
            </v-btn>
            <v-btn v-if="linkedInSwitch" class="mx-1" href="https://linkedin.com/in/AntonyElfferich" target="_blank" fab dark small text>
              <v-icon dark>mdi-linkedin</v-icon>
            </v-btn>
            <v-btn v-if="emailSwitch" class="mx-1" href="mailto:antony@antony.app" target="_blank" fab dark small text>
              <v-icon dark>mdi-email</v-icon>
            </v-btn>
            <v-btn v-if="instagramSwitch" class="mx-1" href="https://instagram.com/Antony.Elfferich" target="_blank" fab dark small text>
              <v-icon dark>mdi-instagram</v-icon>
            </v-btn>
            <v-btn v-if="discordSwitch" class="mx-1" href="https://dsc.bio/Antony" target="_blank" fab dark small text>
              <v-icon dark>mdi-discord</v-icon>
            </v-btn>
          </div>
          <br>
          <div style="align-items: center;">
            <v-icon size="120%" dark>mdi-clock-outline</v-icon> <span v-if="ampm" style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 120%;">{{ date }}<v-icon size="120%" dark>mdi-circle-small</v-icon><a @click='ampm=false'>{{ time12 }}<span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 50%;"> ({{ time12type }})</span></a></span><span v-else style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 120%;">{{ date }}<v-icon size="120%" dark>mdi-circle-small</v-icon><a @click='ampm=true'>{{ time24 }}<span style="font-family: Open Sans; font-weight: light !important; color: #FFFFFF; font-size: 50%;"> (24hr)</span></a></span>
          </div>
          <div v-if="weatherVisible" style="align-items: center;">
            <v-icon size="120%" dark>{{ weatherIcon }}</v-icon> <span v-if="celcius" style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">It's currently <a @click='celcius=false'><b>{{ temperatureC }} 	&deg;C </b></a></span><span v-else style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">It's currently <a @click='celcius=true;'><b>{{ temperatureF }} &deg;F </b></a></span><span style="font-family: Open Sans; color: #FFFFFF; font-size: 70%;"><b>({{ weatherText }})</b></span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;"> in <b>Katwijk aan Zee</b></span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">.</span>
          </div>
          <br>
          <div v-if="activityVisible">
            <v-card dark color="#000000" width="88%" style="border-color: #1F2937;" outlined>
              <a :href="linkLink" target="_blank">
              <div class="d-flex flex-no-wrap">
                <v-avatar class="ma-3" height="100%" rounded>
                  <v-img :src="coverImage"></v-img>
                </v-avatar>
                <div class="ma-3">
                  <span style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 100%;">{{ activityTitle }}</span>
                  <p style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 80%; margin-bottom: 0px;"><v-icon size="80%" :color="activityColor">{{ activityType }}</v-icon> {{ descriptionText }}</p>
                </div>
              </div></a>
            </v-card>
          </div>
          <div style="width: 88%;">
            <span v-if="locationText !='' && locationVisible == true" class="ml-2" style="font-family: Ubuntu; font-weight: bold; color: #FFFFFF; font-size: 85%;"><v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-icon v-bind="attrs" v-on="on" style="margin-right: 1vh;" :color="locationColor" size="60%" dark>mdi-record-circle</v-icon></template><span>{{ locationType }}</span></v-tooltip>{{ locationText }}</span>
            <span style="float: right;" class="mr-2"><v-tooltip v-if="phoneVisible == true && phoneColor != ''" bottom><template v-slot:activator="{ on, attrs }"><v-icon v-bind="attrs" v-on="on" style="margin-right: 0.25vw;" :color="phoneColor" size="90%" dark>mdi-cellphone</v-icon></template><span>{{ phoneStatusText }}</span></v-tooltip></span>
            <span style="float: right;" class="mr-2"><v-tooltip v-if="laptopVisible == true && laptopColor != ''" bottom><template v-slot:activator="{ on, attrs }"><v-icon v-bind="attrs" v-on="on" :color="laptopColor" size="120%" dark>mdi-laptop</v-icon></template><span>{{ laptopStatusText }}</span></v-tooltip></span>
          </div>
        </div>
      </v-container>
      <v-container v-else style="margin-top: 10vh; left: 20vw; right: 20vw; width: 60vw; height: 80vh;">
        <div style="position: absolute; left: 23%; top: 25%;">
          <div>
            <span style="font-family: Ubuntu; font-weight: bold; color: #FFFFFF; font-size: 350%;">Antony.</span>
          </div>
          <div style="width: 70%;">
            <span style="font-family: Open Sans; font-weight: bold; color: #dc143c; font-size: 120%;">Student</span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">,</span><span style="font-family: Open Sans; font-weight: bold; color: #ffcf40; font-size: 120%;"> Software Developer</span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">, and</span><span style="font-family: Open Sans; font-weight: bold; color: #668cff; font-size: 120%;"> UI/UX Designer </span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">from the Netherlands.</span>
          </div>
          <br>
          <div id="Buttons" style="width: 95%;" class="text-left">
            <v-btn v-if="githubSwitch" class="mx-0" href="https://github.com/AntonyElfferich" target="_blank" fab dark small text>
              <v-icon dark>mdi-github</v-icon>
            </v-btn>
            <v-btn v-if="linkedInSwitch" class="mx-1" href="https://linkedin.com/in/AntonyElfferich" target="_blank" fab dark small text>
              <v-icon dark>mdi-linkedin</v-icon>
            </v-btn>
            <v-btn v-if="emailSwitch" class="mx-1" href="mailto:antony@antony.app" target="_blank" fab dark small text>
              <v-icon dark>mdi-email</v-icon>
            </v-btn>
            <v-btn v-if="instagramSwitch" class="mx-1" href="https://instagram.com/Antony.Elfferich" target="_blank" fab dark small text>
              <v-icon dark>mdi-instagram</v-icon>
            </v-btn>
            <v-btn v-if="discordSwitch" class="mx-1" href="https://dsc.bio/Antony" target="_blank" fab dark small text>
              <v-icon dark>mdi-discord</v-icon>
            </v-btn>
          </div>
          <br>
          <div style="width: 90%; align-items: center;">
            <v-icon size="120%" dark>mdi-clock-outline</v-icon> <span v-if="ampm" style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 120%;">{{ date }}<v-icon size="120%" dark>mdi-circle-small</v-icon><a @click='ampm=false'>{{ time12 }}<span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 50%;"> ({{ time12type }})</span></a></span><span v-else style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 120%;">{{ date }}<v-icon size="120%" dark>mdi-circle-small</v-icon><a @click='ampm=true'>{{ time24 }}<span style="font-family: Open Sans; font-weight: light !important; color: #FFFFFF; font-size: 50%;"> (24hr)</span></a></span>
          </div>
          <div v-if="weatherVisible && temperatureC !== ''" style="width: 90%; align-items: center;">
            <v-icon size="120%" dark>{{ weatherIcon }}</v-icon> <span v-if="celcius" style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">It's currently <a @click='celcius=false'><b>{{ temperatureC }} &deg;C </b></a></span><span v-else style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">It's currently <a @click='celcius=true;'><b>{{ temperatureF }} &deg;F </b></a></span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 70%;">({{ weatherText }})</span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;"> in <b>Katwijk aan Zee</b></span><span style="font-family: Open Sans; font-weight: light; color: #FFFFFF; font-size: 120%;">.</span>
          </div>
          <br>
          <div v-if="activityVisible" style="margin-bottom: 1%;">
            <v-card dark color="#000000" width="70%" style="border-color: #1F2937;" outlined>
              <a :href="linkLink" target="_blank">
              <div class="d-flex flex-no-wrap">
                <v-avatar class="ma-3" height="100%" rounded>
                  <v-img :src="coverImage"></v-img>
                </v-avatar>
                <div class="ma-3">
                  <span style="font-family: Open Sans; font-weight: bold; color: #FFFFFF; font-size: 100%;">{{ activityTitle }}</span>
                  <p style="font-family: Open Sans; font-weight: regular; color: #FFFFFF; font-size: 80%; margin-bottom: 0px;"><v-icon size="80%" :color="activityColor">{{ activityType }}</v-icon> {{ descriptionText }}</p>
                </div>
              </div></a>
            </v-card>
          </div>
          <div style="width: 70%;">
            <span v-if="locationText !='' && locationVisible == true" class="ml-2" style="font-family: Ubuntu; font-weight: bold; color: #FFFFFF; font-size: 85%;"><v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-icon v-bind="attrs" v-on="on" style="margin-right: 1vh;" :color="locationColor" size="60%" dark>mdi-record-circle</v-icon></template><span>{{ locationType }}</span></v-tooltip>{{ locationText }}</span>
            <span style="float: right;" class="mr-2"><v-tooltip v-if="phoneVisible == true && phoneColor != ''" bottom><template v-slot:activator="{ on, attrs }"><v-icon v-bind="attrs" v-on="on" style="margin-right: 0.25vw;" :color="phoneColor" size="90%" dark>mdi-cellphone</v-icon></template><span>{{ phoneStatusText }}</span></v-tooltip></span>
            <span style="float: right;" class="mr-2"><v-tooltip v-if="laptopVisible == true && laptopColor != ''" bottom><template v-slot:activator="{ on, attrs }"><v-icon v-bind="attrs" v-on="on" :color="laptopColor" size="120%" dark>mdi-laptop</v-icon></template><span>{{ laptopStatusText }}</span></v-tooltip></span>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import io from "socket.io-client";
  export default {
    data: () => ({
      clientVersion: "Public Build v1.3.6 (20210916.1.3.6)",
      version: "Production Server v0.0.0 (NO DATA)",
      githubSwitch: true,
      linkedInSwitch: true,
      emailSwitch: true,
      discordSwitch: true,
      instagramSwitch: true,
      weatherVisible: false,
      weatherOverride: false,
      weather: "",
      weatherTempF: "",
      weatherTempC: "",
      activityVisible: true,
      activityType: "",
      activityOverride: false,
      title: "",
      description: "",
      cover: "",
      link: "",
      locationVisible: false,
      locationOverride: false,
      locationText: "",
      locationColor: "",
      locationType: "",
      socket: io("https://backend.antony.app"),
      time12: "",
      time12type: "",
      time24: "",
      activityColor: "#1DB954",
      laptopVisible: true,
      phoneVisible: true,
      laptopOverride: false,
      phoneOverride: false,
      laptopOverrideStatus: false,
      phoneOverrideStatus: false,
      laptopColor: "",
      phoneColor: "",
      laptopStatusText: "Offline",
      phoneStatusText: "Offline",
      date: "",
      weatherIcon: "",
      celcius: true,
      ampm: true,
      temperatureC: "",
      temperatureF: "",
      weatherText: "",
      mobile: false,
      nowPlaying: false,
      notPlayingFor: 5,
      descriptionText: "Spotify",
      activityTitle: "Not listening to anything",
      coverImage: "https://media.discordapp.net/attachments/573557115998830652/827338832189587494/musicnote.png",
      linkLink: "https://www.last.fm/user/zAntonyy",
      distanceToLove: 0
    }),
    mounted: function(){
      global.jQuery = require('jquery');
      var $ = global.jQuery;
      window.$ = $;
      var moment = require('moment-timezone');
      var processData = this;

      processData.socket.emit("getData", {}, function (data) {
          if (data) {
              for (var key in data) {
                if (key !== "activityType") {
                  processData[key] = data[key];
                } else {
                  if (data[key] === "Spotify") {
                    processData.activityType = "mdi-spotify"
                    processData.activityColor = "#1DB954"
                  } else if (data[key] === "YouTube") {
                    if (processData.activityOverride == true) {
                      processData.activityType = "mdi-youtube"
                      processData.activityColor = "#FF0000"
                    } else {
                      processData.activityType = "mdi-spotify"
                      processData.activityColor = "#1DB954"
                    }
                  } else if (data[key] === "Netflix") {
                    if (processData.activityOverride == true) {
                      processData.activityType = "mdi-netflix"
                      processData.activityColor = "#E50914"
                    } else {
                      processData.activityType = "mdi-spotify"
                      processData.activityColor = "#1DB954"
                    }
                  } else if (data[key] === "Twitch") {
                    if (processData.activityOverride == true) {
                      processData.activityType = "mdi-twitch"
                      processData.activityColor = "#6441A5"
                    } else {
                      processData.activityType = "mdi-spotify"
                      processData.activityColor = "#1DB954"
                    }
                  }
                }
              }
              console.log(processData.clientVersion)
              console.log(processData.version)
          } else {
              console.log("No Data")
          }
      });

      var getActivity = function() {
        if (processData.activityOverride == false && processData.activityType == "mdi-spotify") {
          processData.socket.emit("getSpotifyData", {}, function (data) {
            if (processData.activityOverride == true) return;
              var JSONData = JSON.parse(data);
              var recentTrack = JSONData.recenttracks.track[0];
              if (recentTrack["@attr"] == undefined) {
                if (processData.notPlayingFor === 0) {
                  processData.notPlayingFor = 5;
                  processData.nowPlaying = false;
                  processData.activityTitle = "Not listening to anything"
                  processData.descriptionText = "Spotify"
                  processData.linkLink = "https://www.last.fm/user/zAntonyy"
                  processData.coverImage = "https://media.discordapp.net/attachments/573557115998830652/827338832189587494/musicnote.png"
                } else {
                  processData.notPlayingFor = processData.notPlayingFor - 1;
                }
              } else {
                processData.activityTitle = recentTrack.name;
                processData.coverImage = recentTrack.image[3]["#text"];
                if (processData.coverImage == "https://lastfm.freetls.fastly.net/i/u/300x300/2a96cbd8b46e442fc41c2b86b821562f.png") {
                    processData.coverImage = "https://media.discordapp.net/attachments/573557115998830652/827338832189587494/musicnote.png"
                }
                processData.descriptionText = recentTrack.artist["#text"];
                processData.linkLink = recentTrack.url;
                processData.nowPlaying = true;
              }
          });
        } else if (processData.activityOverride == false && processData.activityType !== "mdi-spotify") {
          processData.activityType = "mdi-spotify"
        } else {
          processData.activityTitle = processData.title
          processData.descriptionText = processData.description
          if (processData.cover != "") {
            processData.coverImage = processData.cover
          } else {
            processData.coverImage = "https://media.discordapp.net/attachments/573557115998830652/827338832189587494/musicnote.png"
          }
          if (processData.link != "") {
            processData.linkLink = processData.link
          } else {
            processData.linkLink = "https://www.last.fm/user/zAntonyy"
          }
        }
      }

      getActivity();
      setInterval(getActivity, 1000);

      let firstWeatherReq = true;

      var getWeather = function() {
        if (processData.weatherOverride == false) {
          processData.socket.emit("getWeatherData", { firstReq: firstWeatherReq }, function (data) {
            firstWeatherReq = false
            var JSONData = JSON.parse(data);
            var weather = JSONData.current;
            processData.temperatureC = weather.temp_c;
            processData.temperatureF = weather.temp_f;
            processData.weatherText = weather.condition.text;
            // if (weather.condition.code == 1000) {
            //   if (moment().tz("Europe/Amsterdam").format("HH") >= 23 && moment().tz("Europe/Amsterdam").format("HH") <= 7) {
            //     processData.weatherIcon = "mdi-weather-night";
            //   } else {
            //     processData.weatherIcon = "mdi-weather-sunny";
            //   }
            // } else if (weather.condition.code >= 1003 && weather.condition.code <= 1009) {
              processData.weatherIcon = "mdi-weather-cloudy";
            // } else if (weather.condition.code == 1030) {
            //   processData.weatherIcon = "mdi-weather-fog";
            // } else if (weather.condition.code >= 1063 && weather.condition.code <= 1009) {
              // processData.weatherIcon = "mdi-weather-fog";
            // }
          })
        }
      }

      getWeather();
      setInterval(getWeather, 60000);

      let lastWeatherOverride = false;
      var weatherOverride = function() {
        if (processData.weatherOverride == true) {
          processData.weatherText = processData.weather
          processData.temperatureF = processData.weatherTempF
          processData.temperatureC = processData.weatherTempC
          lastWeatherOverride = true
        } else {
          if (lastWeatherOverride == true) {
            lastWeatherOverride = false
            getWeather()
          }
        }
      }

      weatherOverride();
      setInterval(weatherOverride, 1000);

      processData.date = moment().tz("Europe/Amsterdam").format("Do MMMM YYYY");
      processData.time12 = moment().tz("Europe/Amsterdam").format("hh:mm:ss");
      processData.time12type = moment().tz("Europe/Amsterdam").format("a");
      processData.time24 = moment().tz("Europe/Amsterdam").format("HH:mm:ss");
      setInterval(function(){
        processData.date = moment().tz("Europe/Amsterdam").format("Do MMMM YYYY");
        processData.time12 = moment().tz("Europe/Amsterdam").format("hh:mm:ss");
        processData.time12type = moment().tz("Europe/Amsterdam").format("a");
        processData.time24 = moment().tz("Europe/Amsterdam").format("HH:mm:ss");
        if( /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          processData.mobile = true;
        } else {
          processData.mobile = false;
        }
      }, 100);

      setInterval(function(){
        processData.socket.emit("getData", {}, function (data) {
            if (data) {
                for (var key in data) {
                  if (key !== "activityType") {
                    processData[key] = data[key];
                  } else {
                    if (data[key] === "Spotify") {
                      processData.activityType = "mdi-spotify"
                      processData.activityColor = "#1DB954"
                    } else if (data[key] === "YouTube") {
                      if (processData.activityOverride == true) {
                        processData.activityType = "mdi-youtube"
                        processData.activityColor = "#FF0000"
                      } else {
                        processData.activityType = "mdi-spotify"
                        processData.activityColor = "#1DB954"
                      }
                    } else if (data[key] === "Netflix") {
                      if (processData.activityOverride == true) {
                        processData.activityType = "mdi-netflix"
                        processData.activityColor = "#E50914"
                      } else {
                        processData.activityType = "mdi-spotify"
                        processData.activityColor = "#1DB954"
                      }
                    } else if (data[key] === "Twitch") {
                      if (processData.activityOverride == true) {
                        processData.activityType = "mdi-twitch"
                        processData.activityColor = "#6441A5"
                      } else {
                        processData.activityType = "mdi-spotify"
                        processData.activityColor = "#1DB954"
                      }
                    }
                  }
                }
            } else {
                console.log("No Data")
            }
        });
      }, 1000);
    }
  }
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  background-color: #000000
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}

a {
  all: unset;
}

.v-application a {
  color: #FFFFFF !important;
}
</style>
